
body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none; /* Disables bounce effect on vertical scroll */
  overscroll-behavior-x: none; /* Disables bounce effect on vertical scroll */
  letter-spacing: -.2px;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4 {
  font-family: 'Metropolis', sans-serif;

}

h1 {
  font-size: 88px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 24px;
}

p {
  
}

/* @font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/metropolis/Metropolis-Regular.otf') format('truetype');
  /* Add other font variants as needed */


@font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/metropolis/Metropolis-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/metropolis/Metropolis-ThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/metropolis/Metropolis-ExtraLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/metropolis/Metropolis-ExtraLightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/metropolis/Metropolis-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/metropolis/Metropolis-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/metropolis/Metropolis-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/metropolis/Metropolis-RegularItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/metropolis/Metropolis-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/metropolis/Metropolis-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/metropolis/Metropolis-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/metropolis/Metropolis-SemiBoldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/metropolis/Metropolis-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/metropolis/Metropolis-BoldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/metropolis/Metropolis-ExtraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/metropolis/Metropolis-ExtraBoldItalic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/metropolis/Metropolis-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/metropolis/Metropolis-BlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

